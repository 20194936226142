<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Edit Cetak Emas
        <div class="card-header-actions">
          <a class="card-header-action" href="gold-reedems" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
              <div class="sk-three-bounce">
                  <div class="sk-child sk-bounce1"></div>
                  <div class="sk-child sk-bounce2"></div>
                  <div class="sk-child sk-bounce3"></div>
              </div>
          </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="dinarPost"  novalidate>
              <b-form-group id="mintingPartnerInputGroup1"
                            label="Minting Partner"
                            label-for="mintingPartner">
                <b-form-input id="minting_partner"
                              type="text"
                              v-model="form.minting_partner"
                              aria-describedby="input1LiveFeedback1"
                              placeholder="minting_partner"
                              :disabled="disabled == 1 ? true : false"
                              autofocus />
              </b-form-group>
                <b-form-group
                 id="shippingMethodInputGroup"
                 label="Shipping Method"
                 label-for="shippingMethod"
                >
                  <b-form-input
                   id="shippingMethod"
                   type="text"
                   v-model="form.shipping_method"
                   aria-describedby="shippingMethodFeedback"
                   placeholder="Shipping Method"
                   :disabled="disabled == 1 ? true : false"
                   autofocus
                  />
                  <b-form-invalid-feedback id="shippingMethodFeedback">
                  This is a required field and must be at least 2 characters
                </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="rContentInputGroup4"
                            label="Status"
                            label-for="rContent">
                    <b-form-select id="v_type"
                                  v-model="form.v_type"
                                  :options="[{value: '', text:'Please select'},
                                                {value: 'Menunggu Pembayaran', text: 'Menunggu Pembayaran'},
                                                {value: 'processing', text: 'Sedang Diproses'},
                                                {value: 'awaiting-shipment', text: 'Sedang Dikirim'},
                                                {value: 'completed', text: 'Selesai'}]"
                                  aria-describedby="statusFeedback"
                                  :plain="true">
                    </b-form-select>
                  <b-form-invalid-feedback id="statusFeedback" v-for="(error , index) in errors.form.status" :key="index">
                    - {{ error }} <br>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="nomorResiInputGroup1"
                            label="Nomor Resi"
                            label-for="nomorResi">
                    <b-form-input id="nomorResi"
                                type="text"
                                v-model="form.nomorResi"
                                aria-describedby="trackingFeedback"
                                placeholder="Name"
                                autofocus />
                </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        minting_partner: '',
        v_type: '',
        nomorResi: '',
        shipping_method: ''
      },
      disabled: 1,
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          status: [],
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  created(){
    this.$http.put(`redeem-data/` + this.$route.params.id + `?type=` + this.$route.params.type)
    .then((result) => {
      this.form.minting_partner = result.data.data.partner
      this.form.shipping_method = result.data.data.shipping_provider
      this.form.v_type = result.data.data.status
      this.form.nomorResi = result.data.data.receipt_number === null ? '' : result.data.data.receipt_number
    }).catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
  methods: {
    dinarPost() {
      this.isLoading = true
        const formData = new FormData();
        formData.append('status', this.form.v_type)
        formData.append('tracking_no', this.form.nomorResi)

        this.$http.post(`redeem-update/` + this.$route.params.id + `?type=` + this.$route.params.type, formData)
        .then(() => {
          this.isLoading = false
          this.$router.push({name: 'List Cetak Emas'});
          this.$toasted.success('Cetak Emas successfully updated!')
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
            if(this.errors.status == 422) {
              this.$toasted.error('Please fill out the form that must be required')
              this.errors.message = error.response.data.meta.message;
              this.errors.form.status = this.errors.message.status;
              this.errors.form.tracking_no = this.errors.message.tracking_no;
            }
          }
        })
    }
    // chkState(val) {
    //   const field = this.$v.form[val]
    //   return !field.$dirty || !field.$invalid
    // },
    // findFirstError(component = this) {
    //   if (component.state === false) {
    //     if (component.$refs.input) {
    //       component.$refs.input.focus()
    //       return true
    //     }
    //     if (component.$refs.check) {
    //       component.$refs.check.focus()
    //       return true
    //     }
    //   }
    //   let focused = false
    //   component.$children.some((child) => {
    //     focused = this.findFirstError(child)
    //     return focused
    //   })

    //   return focused
    // },
    // validate() {
    //   this.$v.$touch()
    //   this.$nextTick(() => this.findFirstError())
    //   return this.isValid
    // }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
